//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueQr from "vue-qr";
export default {
  props: {
    mName: {
      type: String,
      default: "invitation",
    },
    teamId: {
      type: Number,
      default: null,
    },
  },
  components: {
    VueQr,
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      loading: false,
      linkCode: null, //链接邀请码
      ruleForm: {
        teamValue: null,
        invType: 1,
      },
      teamOptions: [],
      rules: {
        teamValue: [
          { required: true, message: "请选择团队", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    /**
     * 弹窗打开回调
     */
    opened() {
      Object.assign(this.$data, this.$options.data());
      this.getTeams();
      this.$nextTick(() => {
        if (this.teamId) {
          this.ruleForm.teamValue = this.teamId;
          this.onConfirm();
        }
      });
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data;
          }
        });
    },

    /**
     * 邀请
     */
    onConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            teamId: this.ruleForm.teamValue,
          };
          this.$http
            .post("/Teams/InvitedOrApply/CreateInvitedCode.ashx", data)
            .then((resp) => {
              if (resp.res == 0) {
                if (resp.data) {
                  this.linkCode = resp.data;
                } else {
                  this.$message({
                    showClose: true,
                    message: resp.msg,
                    type: "warning",
                  });
                }
              }
            })
            .finally(() => (this.loading = false));
        } else {
          return;
        }
      });
    },
    //复制链接后的回调
    copySuccess: function () {
      this.$notify({
        type: "success",
        title: "复制成功",
      });
    },
    copyError: function () {
      this.$notify({
        type: "error",
        title: "复制失败",
        msg: "请确认浏览器是否支持此功能",
      });
    },
  },
};
